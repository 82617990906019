import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBusinessList() {
  // Use toast
  const toast = useToast();

  const refMusicalWorksList = ref(null);

  const isLoading = ref(false)

  // Table Handlers
  const tableColumns = [
    { key: 'title' },
    { key: 'status' },
    { key: 'uploaded_on' },
    { key: 'actions' },
  ]
  const per_page = ref(10)
  const totalRecords = ref(0)
  const current_page = ref(1)
  const per_pageOptions = [5, 10, 15, 100]
  const searchQuery = ref('')
  const type = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const contentData = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refMusicalWorksList.value
      ? refMusicalWorksList.value.localItems.length
      : 0
    return {
      from:
        per_page.value * (current_page.value - 1) + (localItemsCount ? 1 : 0),
      to: per_page.value * (current_page.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    refMusicalWorksList.value.refresh()
  }

  watch([current_page, per_page, searchQuery, type], () => {
    refetchData()
  })

  const fetchMusicalWorks = (ctx, callback) => {
    store
      .dispatch('content/fetchMusicalWorks', {
        q: searchQuery.value,
        per_page: per_page.value,
        page: current_page.value,
      })
      .then(response => {
        const { data: content, total } = response.data.content
        callback(content)
        totalRecords.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching your content',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveContentVariant = status => {
    if (status === 'Approved') return 'success'
    if (status === 'Pending') return 'secondary'
    if (status === 'Denied') return 'danger'
    return 'primary'
  }

  return {
    fetchMusicalWorks,
    tableColumns,
    per_page,
    current_page,
    totalRecords,
    dataMeta,
    per_pageOptions,
    searchQuery,
    type,
    sortBy,
    isSortDirDesc,
    refMusicalWorksList,
    refetchData,

    contentData,

    resolveContentVariant,

    isLoading,
  }
}
